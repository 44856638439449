import { InView } from 'react-intersection-observer';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import groupBy from 'lodash.groupby';
import { defineMessages, useIntl } from 'react-intl';
import { SimpleTemplateContainer } from '..';
import BoxTithe from '@components/molecules/box-tithe/box-tithe';
import BackLink from '@components/molecules/back-link/back-link';
import {
  formatAddress,
  getOrganizationCurrency,
} from '@hooks/use-organization';
import { useEnvelope } from '@hooks/use-envelope';
import TitheEnvelopeCategory from '@components/organisms/tithe-envelope-category/tithe-envelope-category';
import TitheEnvelopeTotals from '@components/organisms/tithe-envelope-totals/tithe-envelope-totals';
import useTitheForm, { shouldShowCurrency } from '@hooks/use-tithe-form';
import { LoadingSpinner } from '@components/atoms/loading-spinner/loading-spinner';
import { PaymentSchedule } from '@models/payment-schedule';
import {
  PaymentCategories,
  PaymentCategory,
  PaymentCategoryType,
  PAYMENT_CATEGORY_TYPE_OPTIONS,
} from '@models/payment-category';
import { Heading } from '@components/atoms/heading/heading';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';
import { useCurrentUser } from '@hooks/use-user';
import TitheEnvelopeTitle from '@components/organisms/tithe-envelope-title/title-envelope-title';
import EnvelopeOfferingOfTheWeek from '@components/molecules/envelope-offering-of-the-week/envelope-offering-of-the-week';
import { Organization } from '@models/organization';

const messages = defineMessages({
  labelBackToRecurring: {
    id: 'PMdYXj',
    defaultMessage: 'Back to Recurring Donations',
    description: 'Link label to get back to recurring donations account page.',
  },
});

type EnvelopeCategoryMap = {
  [key in PaymentCategoryType]: PaymentCategory[];
};

type EnvelopeCategoryLineItemsMap = {
  [key in PaymentCategoryType]: {
    inViewCallback: Dispatch<SetStateAction<boolean>>;
  };
};

const getPaymentScheduleExtraCategories = (
  paymentSchedule?: PaymentSchedule,
  envelope?: PaymentCategories
) => {
  const recurringCategoryMap = paymentSchedule
    ? groupBy(paymentSchedule.line_items, 'payment_category.payment_group.id')
    : {};
  return Object.keys(recurringCategoryMap).reduce(
    (tally, category) => ({
      [category]: recurringCategoryMap[category]
        .filter((item) => {
          return (
            !envelope ||
            !envelope.payment_categories[category as PaymentCategoryType].find(
              (c) => c.id === item.payment_category.id
            )
          );
        })
        .map(
          (item) =>
            ({
              id: item.payment_category.id,
              name: item.payment_category.name,
              description: item.payment_category.description,
              organization_id: item.payment_category.organization.id,
            }) as Partial<PaymentCategory>
        ),
      ...tally,
    }),
    {} as EnvelopeCategoryMap
  );
};

interface Props {
  action?: 'new' | 'edit';
  paymentSchedule?: PaymentSchedule;
  organization: Organization;
}
const TitheEnvelope = ({
  organization,
  paymentSchedule,
  action = 'new',
}: Props) => {
  const intl = useIntl();
  const orgId = organization.id;
  const { data: envelope } = useEnvelope({ orgId });
  const { data: user } = useCurrentUser();
  const showCurrency = shouldShowCurrency(user, organization);
  const currency = organization && getOrganizationCurrency(organization);
  const {
    values,
    handleSubmitFromEnvelope,
    hasSubmitted,
    updateValues,
    totals,
    grandTotal,
    grandTotalError,
    hasInitialized,
    isSubmitting,
  } = useTitheForm(
    action === 'edit' ? 'recurring_update' : 'envelope',
    orgId,
    paymentSchedule
  );
  const [totalsHeight, setTotalsHeight] = useState(0);
  const [titheInView, setTitheInView] = useState(false);
  const [churchInView, setChurchInView] = useState(false);
  const [churchParentInView, setChurchParentInView] = useState(false);
  const [worldInView, setWorldInView] = useState(false);
  const refs = {
    tithe: useRef<HTMLDivElement>(null),
    church: useRef<HTMLDivElement>(null),
    'church.parent': useRef<HTMLDivElement>(null),
    world: useRef<HTMLDivElement>(null),
  };
  const offeringOfTheWeekRef = useRef<HTMLInputElement>(null);
  const localOfferingRef = useRef<HTMLInputElement>(null);
  const conferenceOfferingRef = useRef<HTMLInputElement>(null);
  const worldOfferingRef = useRef<HTMLInputElement>(null);
  const isLoading = !hasInitialized || !envelope;
  const extraCategoriesMap = getPaymentScheduleExtraCategories(
    paymentSchedule,
    envelope
  );
  const categoryLineItemMap: EnvelopeCategoryLineItemsMap = {
    tithe: {
      inViewCallback: setTitheInView,
    },
    church: {
      inViewCallback: setChurchInView,
    },
    'church.parent': {
      inViewCallback: setChurchParentInView,
    },
    world: {
      inViewCallback: setWorldInView,
    },
  };

  const findCategoryById = (id?: string) => {
    if (!envelope || !id) {
      return undefined;
    }
    const categories = Object.keys(
      envelope.payment_categories
    ) as PaymentCategoryType[];
    for (const category of categories) {
      const found = envelope.payment_categories[category].find(
        (c) => c.id === id
      );
      if (found) {
        return found;
      }
    }
  };

  const offeringOfTheWeekCategory = findCategoryById(
    envelope?.offering_of_the_week.payment_category_id
  );

  useEffect(() => {
    if (paymentSchedule) {
      // payment schedule edit feature
      // we need to clear session storage to make sure a payment schedule's
      // line items are always present on load.
      PAYMENT_CATEGORY_TYPE_OPTIONS.forEach((category) => {
        sessionStorage.removeItem(`saved_categories_${orgId}_${category}`);
      });
    }
  }, [paymentSchedule, orgId]);

  return (
    <div
      className="lg:!pb-0"
      style={{
        paddingBottom: totalsHeight + 'px',
      }}
    >
      <SimpleTemplateContainer
        withMargin={false}
        className="items-center justify-center md:mt-10 lg:my-10"
      >
        {action == 'edit' && (
          <div className=" container my-7">
            <BackLink
              label={intl.formatMessage(messages.labelBackToRecurring)}
              href="/account/recurring-donations"
            />
          </div>
        )}

        <form
          method="post"
          onSubmit={handleSubmitFromEnvelope}
          autoComplete="off"
        >
          <div className="flex items-center flex-col bg-nad-light-blue py-12 space-y-5 w-full">
            <div className="container m-auto text-center">
              <Heading variant="h2" as="h1" color="nad-blue">
                {organization?.name}
              </Heading>
              <HeadingUppercase variant="h6" color="nad-blue" className="mt-3">
                {organization && formatAddress(organization)}
              </HeadingUppercase>
            </div>
          </div>
          {isLoading && (
            <div className="lg:container mt-9 flex justify-center">
              <LoadingSpinner color="blue" />
            </div>
          )}
          {!isLoading && (
            <div className="lg:container lg:mt-9 lg:space-y-6">
              {envelope.offering_of_the_week && (
                <EnvelopeOfferingOfTheWeek
                  item={envelope.offering_of_the_week}
                  category={offeringOfTheWeekCategory}
                  onCategoryClick={() => {
                    offeringOfTheWeekRef.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    });
                    offeringOfTheWeekRef.current?.focus({
                      preventScroll: true,
                    });
                  }}
                  onScrollClick={() => {
                    if (envelope.offering_of_the_week.week_type === 'church') {
                      localOfferingRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      });
                      localOfferingRef.current?.focus({
                        preventScroll: true,
                      });
                    } else if (
                      envelope.offering_of_the_week.week_type ===
                      'church_parent'
                    ) {
                      conferenceOfferingRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      });
                      conferenceOfferingRef.current?.focus({
                        preventScroll: true,
                      });
                    } else if (
                      envelope.offering_of_the_week.week_type === 'world'
                    ) {
                      worldOfferingRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      });
                      worldOfferingRef.current?.focus({
                        preventScroll: true,
                      });
                    }
                  }}
                />
              )}
              <div className="w-full flex flex-col lg:flex-row lg:space-x-7 relative">
                <div className="w-full lg:w-7/12 lg:space-y-6 divide-y-2 border-y-2 lg:divide-y-0 lg:border-y-0">
                  {PAYMENT_CATEGORY_TYPE_OPTIONS.map((category) => (
                    <InView
                      key={category}
                      as="div"
                      threshold={0}
                      rootMargin="-100px"
                      onChange={(inView, entry) =>
                        categoryLineItemMap[category].inViewCallback(inView)
                      }
                    >
                      <BoxTithe ref={refs[category]}>
                        <TitheEnvelopeTitle
                          category={category}
                          localOfferingRef={localOfferingRef}
                          conferenceOfferingRef={conferenceOfferingRef}
                          worldOfferingRef={worldOfferingRef}
                        />

                        {envelope && (
                          <TitheEnvelopeCategory
                            orgId={orgId}
                            category={category}
                            categories={envelope.payment_categories[
                              category
                            ].concat(extraCategoriesMap[category] || [])}
                            offeringOfTheWeek={offeringOfTheWeekCategory}
                            offeringOfTheWeekRef={offeringOfTheWeekRef}
                            total={envelope.group_counts[category]}
                            values={values.line_items[category]}
                            setValues={(_values) =>
                              updateValues({
                                line_items: {
                                  ...values.line_items,
                                  [category]: _values,
                                },
                              })
                            }
                          />
                        )}
                        {category === 'world' &&
                          !envelope.payment_categories[category].length && (
                            <div className="h-9 lg:hidden"></div>
                          )}
                      </BoxTithe>
                    </InView>
                  ))}
                </div>
                <div className="w-full lg:w-5/12 fixed bottom-0 left-0 right-0 lg:static bg-white z-10 lg:z-0">
                  <TitheEnvelopeTotals
                    className="shadow-2xl shadow-nad-blue lg:shadow-none lg:border-x-4 lg:border-t-4 lg:sticky lg:top-6 lg:mt-0"
                    isSubmitting={isSubmitting}
                    totals={totals}
                    grandTotal={grandTotal}
                    error={hasSubmitted ? grandTotalError : undefined}
                    isRecurringUpdate={action === 'edit'}
                    itemsInView={{
                      tithe: titheInView,
                      church: churchInView,
                      'church.parent': churchParentInView,
                      world: worldInView,
                    }}
                    showCurrency={showCurrency}
                    currency={currency}
                    onClick={(category) => {
                      if (refs[category] && refs[category].current) {
                        refs[category].current?.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }}
                    onElementHeight={(height) => {
                      setTotalsHeight(height);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </form>
      </SimpleTemplateContainer>
    </div>
  );
};

export default TitheEnvelope;
